import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  fullImagePathImg0: string;
  fullImagePathImg1: string;
  fullImagePathImg2: string;
  fullImagePathImg3: string;
  fullImagePathImg4: string;
  fullImagePathImg5: string;
  fullImagePathImg6: string;
  fullImagePathImg7: string;
  fullImagePathImg8: string;
  fullImagePathImg9: string;
  fullImagePathImg10: string;
  fullImagePathImg11: string;
  fullImagePathImg12: string;
  fullImagePathImg13: string;
  fullImagePathImg14: string;


  constructor() { 
    //this.fullImagePathImg1 = '../assets/images/art1.jpg';
    //this.fullImagePathImg2 = '../../assets/images/flower.jpg';
    //this.fullImagePathImg3 = '../../../assets/images/bird.png';

    //this.fullImagePathImg1 = '/images/art1.jpg';
    //this.fullImagePathImg2 = '/images/flower.jpg';
    //this.fullImagePathImg3 = '/images/bird.png';

    //this.fullImagePathImg1 = 'assets/images/art1.jpg';
    //this.fullImagePathImg2 = 'assets/images/flower.jpg';
    //this.fullImagePathImg3 = 'assets/images/bird.png';

    this.fullImagePathImg0 = 'assets/images/ph0.jpg';
    this.fullImagePathImg1 = 'assets/images/ph1.jpg';
    this.fullImagePathImg2 = 'assets/images/ph2.jpg';
    this.fullImagePathImg3 = 'assets/images/ph3.jpg';
    this.fullImagePathImg4 = 'assets/images/ph4.jpg';
    this.fullImagePathImg5 = 'assets/images/ph5.jpg';
    this.fullImagePathImg6 = 'assets/images/ph6.jpg';
    this.fullImagePathImg7 = 'assets/images/ph7.jpg';
    this.fullImagePathImg8 = 'assets/images/ph8.jpg';
    this.fullImagePathImg9 = 'assets/images/ph9.jpg';
    this.fullImagePathImg10 = 'assets/images/ph10.jpg';
    this.fullImagePathImg11 = 'assets/images/ph11.jpg';
    this.fullImagePathImg12 = 'assets/images/ph12.jpg';
    this.fullImagePathImg13 = 'assets/images/ph13.jpg';
    this.fullImagePathImg14 = 'assets/images/ph14.jpg';

  }

  ngOnInit() {
  }

}
