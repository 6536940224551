import { Component, OnInit } from '@angular/core';
import { TodoSrvService } from '../../services/todo-srv.service';
import { Todo } from '../../models/Todo';

@Component({
  selector: 'app-todos-comp',
  templateUrl: './todos-comp.component.html',
  styleUrls: ['./todos-comp.component.css']
})
export class TodosCompComponent implements OnInit {
  todos:Todo[];

  constructor( private todoService:TodoSrvService) { }

  ngOnInit() {
    this.todoService.getTodos().subscribe(todos => {
      this.todos = todos;
    });
  }

  deleteTodo(todo:Todo) {
    console.log('Delete me');
    this.todos = this.todos.filter ( t => t.id != todo.id);

    this.todoService.deleteTodo(todo).subscribe();
  }

  addTodo(todo:Todo) {
    console.log('Add me');
    //this.todos = this.todos.filter ( t => t.id != todo.id);

    this.todoService.addTodo(todo).subscribe( todo => {
      this.todos.push(todo);
    });
  }  
}
