import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Todo } from 'src/app/models/Todo';
import { TodoSrvService } from '../../services/todo-srv.service';

@Component({
  selector: 'app-todo-item-comp',
  templateUrl: './todo-item-comp.component.html',
  styleUrls: ['./todo-item-comp.component.css']
})
export class TodoItemCompComponent implements OnInit {
  @Input() todo: Todo;
  @Output() deleteTodo: EventEmitter<Todo> = new EventEmitter();

  constructor(private todoService:TodoSrvService) { }

  ngOnInit() {
  }

  // Set Dynamic Classes
  setClasses() {
    let classes = {
      todo: true,
      'is-complete': this.todo.completed
    }

    return classes;
  }

  onToggle(todo) {
    // Toggle in UI
    console.log('toggle');
    todo.completed = !todo.completed;

    // Toggl on server
    this.todoService.toggleCompleted(todo).subscribe(todo=>console.log(todo));
  }


  onDelete(todo) {
    console.log('delete');
    this.deleteTodo.emit(todo);
  }  
}
